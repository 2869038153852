@use "sass:math";

// Setup
html {
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
	margin: 0;
	font-family: "Open Sans", sans-serif;
	font-size: 12px;
	min-height: 100%;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
}

#root {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

// Overrides
a {
	text-decoration: none;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

// Utility classes
.f {
	display: flex;

	&.jc,
	&.c {
		justify-content: center;
	}

	&.jsb {
		justify-content: space-between;
	}

	&.jsa {
		justify-content: space-around;
	}

	&.js {
		justify-content: flex-start;
	}

	&.je {
		justify-content: flex-end;
	}

	&.ac,
	&.c {
		align-items: center;
	}

	&.as {
		align-items: flex-start;
	}

	&.ae {
		align-items: flex-end;
	}

	&.row {
		flex-direction: row;
	}

	&.col {
		flex-direction: column;
	}

	&.grow {
		flex-grow: 1;
	}

	&.ngrow {
		flex-grow: 0;
	}

	&.shrink {
		flex-shrink: 1;
	}

	&.nshrink {
		flex-shrink: 0;
	}

	&.exp {
		flex-grow: 1;
		flex-basis: 1px;
	}

	&.fix {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 0;
	}

	&.wrap {
		flex-wrap: wrap;
	}
}

.f-jc,
.f-c {
	justify-content: center;
}

.f-jsb {
	justify-content: space-between;
}

.f-jsa {
	justify-content: space-around;
}

.f-js {
	justify-content: flex-start;
}

.f-je {
	justify-content: flex-end;
}

.f-ac,
.f-c {
	align-items: center;
}

.f-as {
	align-items: flex-start;
}

.f-ae {
	align-items: flex-end;
}

.f-row {
	flex-direction: row;
}

.f-col {
	flex-direction: column;
}

.f-grow {
	flex-grow: 1;
}

.f-ngrow {
	flex-grow: 0;
}

.f-shrink {
	flex-shrink: 1;
}

.f-nshrink {
	flex-shrink: 0;
}

.f-exp {
	flex-grow: 1;
	flex-basis: 1px;
}

.f-fix {
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: 0;
}

.upper-case { text-transform: uppercase }
.lower-case { text-transform: lowercase }
.title-case { text-transform: capitalize }

.text-left { text-align: left }
.text-center { text-align: center }
.text-right { text-align: right }
.text-justify { text-align: justify }

.fw-bold { font-weight: bold }
.fw-normal { font-weight: normal }

.ell {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/* Removes increment/decrement arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

@function str-replace($string, $search, $replace: '') {
	$string: "" + $string;

	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@function str-split($str, $separator: " ", $allow-empty: false) {
	$split: ();
	$idx: str-index($str, $separator);
	$len: str-length($separator);

	@while $idx != null {
		$component: str-slice($str, 1, $idx - 1);

		@if $component != "" or $allow-empty {
			$split: append($split, $component);
		}

		$str: str-slice($str, $idx + $len);
		$idx: str-index($str, $separator)
	}

	@if $str != "" or $allow-empty {
		$split: append($split, $str);
	}

	@return $split;
}

@mixin rangeClass($slug, $property, $unit, $start, $end, $step:1) {
	$steps: math.div($end - $start, $step);

	@for $i from 0 through $steps {
		$val: $start + $i * $step;

		.#{$slug + str-replace($val, ".", "_")} {
			#{$property}: #{$val + $unit};
		}
	}
}

@include rangeClass("mxw", "max-width", "px", 50, 400, 50);
@include rangeClass("mnw", "min-width", "px", 50, 400, 50);
@include rangeClass("o", "opacity", "", 0, 1, 0.1);
@include rangeClass("mt", "margin-top", "px", -10, 50, 5);
@include rangeClass("mb", "margin-bottom", "px", -10, 50, 5);
@include rangeClass("ml", "margin-left", "px", -10, 50, 5);
@include rangeClass("mr", "margin-right", "px", -10, 50, 5);
@include rangeClass("fs", "font-size", "%", 50, 160, 5);
@include rangeClass("p", "padding", "px", 0, 50, 5);
